import React from 'react';
import CTAButton from './ctabutton.component';
import '../styles/pricing.scss';

export default function Pricing(props) {
	return (
		<div id='pricing'>
			<h2>Precios</h2>
			<div className='pricing_columns'>
				<div className='pricing_column'>
					<h4>Plan R2D2</h4>
					<h5 className='price'>Gratis</h5>
					<p>
						<span>Procesos ilimitados ∞</span>
						Tus procesos críticos bajo control
					</p>
					<p>
						<span>Hasta 5 agentes</span>
						Cada agente es una persona o aplicación
					</p>
					<p>
						<span>200MB de almacenamiento</span>
						por organización
					</p>
				</div>
				<div className='pricing_column'>
					<h4>Plan T800</h4>
					<h5 className='price'>10€/agente</h5>
					<p>
						<span>Procesos ilimitados ∞</span>
						0,30€ por cada ejecución de proceso
					</p>
					<p>
						<span>Hasta 10 agentes</span>
						Más manos y robots a trabajar
					</p>
					<p>
						<span>Almacenamiento a medida</span>
						0,35€ por cada GB consumido
					</p>
				</div>
				<div className='pricing_column'>
					<h4>Plan Optimus</h4>
					<h5 className='price'>Personalizado</h5>
					<p>
						<span>Procesos ilimitados ∞</span>
						Precio por ejecución a medida
					</p>
					<p>
						<span>+10 agentes</span>La imaginación es el límite
					</p>
					<p>
						<span>Almacenamiento a medida</span>
						0,30€ por cada GB consumido
					</p>
					<CTAButton inverted mail color='lblue'>
						Pregúntanos
					</CTAButton>
				</div>
			</div>
		</div>
	);
}
