import React from 'react';
import { Link } from 'gatsby';
import '../styles/faqaccordion.scss';

export default function FAQaccordion() {
	return (
		<div className='tabs'>
			<div className='tab'>
				<input type='checkbox' id='chck1' />
				<label className='tab-label' htmlFor='chck1'>
					¿Es KuFlow apta para mi organización?
				</label>
				<div className='tab-content'>
					<p>
						KuFlow es perfecta para cualquier tipo de organización, aunque
						brilla especialmente cuanto más complejos sean los procesos.
					</p>
					<p>
						Piensa en KuFlow como un{' '}
						<strong>
							Departamento de Organización de Tareas y Automatización de
							Marrones
						</strong>
						.
					</p>
					<p>
						En empresas de 4 personas puede ser un poco exagerado, pero cuando
						sean 40... Desearás tener este nuevo departamento a pleno
						rendimiento.
					</p>
				</div>
			</div>
			<div className='tab'>
				<input type='checkbox' id='chck2' />
				<label className='tab-label' htmlFor='chck2'>
					¿En qué tipo de procesos podría usar KuFlow?
				</label>
				<div className='tab-content'>
					<p>Prácticamente cualquiera que se te ocurra:</p>
					<ul>
						<li>
							Aprobación de compras, desde la petición inicial hasta el contacto
							con el proveedor y almacenar la factura.
						</li>
						<li>Solicitar vacaciones en la empresa.</li>
						<li>Recibir feedback del cliente y reutilizar esa información.</li>
						<li>
							Gestionar la relación con el cliente desde la propuesta inicial
							hasta el producto entregado.
						</li>
						<li>
							Recordar automáticamente a clientes documentos o autorizaciones
							pendientes.
						</li>
						<li>Revisar cada X tiempo alguna web y prepararte un informe</li>
					</ul>
					<p>Si se puede pensar como un diagrama de flujos, se puede hacer.</p>
					<p>
						Puedes leer más ejemplos viendo los casos de uso usando KuFlow para{' '}
						<Link to='/es/solution-workflows'>Flujos de trabajo</Link>,{' '}
						<Link to='/es/solution-rpa'>Automatización inteligente</Link> u{' '}
						<Link to='/es/solution-orchestration'>Orquestación</Link>.
					</p>
				</div>
			</div>
			<div className='tab'>
				<input type='checkbox' id='chck3' />
				<label className='tab-label' htmlFor='chck3'>
					¿Para qué NO sirve KuFlow?
				</label>
				<div className='tab-content'>
					<p>Por desgracia, KuFlow no es una navaja suiza.</p>
					<ul>
						<li>
							No sirve como herramienta de BPM. Aquí no tenemos cajitas ni
							flechitas.
						</li>
						<li>
							No va a sustituir herramientas de contabilidad o de RRHH, aunque
							se puede llevar bien con ellas y hacer cosas potentes juntas.
						</li>
						<li>
							No está recomendado para empresas no-code. Si al ver líneas de
							código te salen ronchas, tal vez hay mejores alternativas.
						</li>
					</ul>
				</div>
			</div>
			<div className='tab'>
				<input type='checkbox' id='chck4' />
				<label className='tab-label' htmlFor='chck4'>
					¿Necesito personal técnico en mi organización?
				</label>
				<div className='tab-content'>
					<p>
						Definitivamente <strong>SÍ</strong>.
					</p>
					<p>
						Tanto para la creación de flujos como para la automatización de
						tareas, vas a necesitar alguien que sepa hablar con las máquinas.
					</p>
					<p>
						¿No tienes a ningún perfil así? Entonces te interesa saber que
						ofrecemos ayuda en la implementación.
					</p>
				</div>
			</div>
		</div>
	);
}
