import React, { useState } from 'react';
import { Link } from 'gatsby';
import Seo from '../components/seo.es';
import LayoutES from '../components/layout.es';
import SolutionsBlockES from '../components/solutionsblock.component.es';
import Pricing from '../components/pricing.component.es';
import FAQaccordion from '../components/faqaccordion.component.es';
import LatestPostsES from '../components/latestposts.component.es';
import Benefit from '../components/benefit.component';
import CTAButton from '../components/ctabutton.component';
import ModalVideo from '../components/modalvideo.component';
import ModalNewsletter from '../components/modalnewsletter.component';

import '../styles/index.scss';
import heroImage from '../static/images/KuFlow-Flujos_hiperautomatizados.gif';

const IndexPage = () => {
	const [show, setShow] = useState(false);
	const [showForm, setShowForm] = useState(false);
	return (
		<LayoutES>
			<Seo title='Inicio' />
			<section id='hero'>
				<div id='hero-text' className='hero-column'>
					<h1>
						Flujos de trabajo auto<strong>mágicos</strong>
					</h1>
					<p>
						La magia de ahorrar más tiempo y ganar más dinero y que{' '}
						<strong>parezca</strong> que todo funciona igual.
					</p>
					<div className='buttonPanel'>
						<CTAButton color='lblue'>COMENZAR GRATIS</CTAButton>
						<ModalVideo
							onClose={() => setShow(false)}
							show={show}
							videoID='oXHMMuDzJ2M'
						/>
						<a className='btn-primary' onClick={() => setShow(true)}>
							KUFLOW EN 1 MINUTO <strong>▶️</strong>
						</a>
					</div>
				</div>
				<div id='hero-image' className='hero-column'>
					<img src={heroImage} alt='Trabajo cómodo con KuFlow' />
				</div>
			</section>
			<section id='featured-article'>
				<Link to='/blog/es/leaders-automation/' className='blink'>
					Cómo y qué hiperautomatizan las empresas líderes
				</Link>
			</section>
			<SolutionsBlockES />
			<section id='benefits'>
				<h2>¿Cómo lo hace?</h2>
				<Benefit
					posImage='R'
					imageUrl='/images/kuflow_orquesta.png'
					linkTo='/es/solution-workflow/'
					linkText='Ver más'
				>
					<h3>KuFlow orquesta</h3>
					<p>
						KuFlow se puede denominar como un{' '}
						<strong>
							orquestador de tareas, tanto humanas como automatizadas.
						</strong>
					</p>
					<p>
						Se encarga de decirle a cada agente qué tareas y qué procesos hay
						que ejecutar.
					</p>
				</Benefit>
				<Benefit
					posImage='L'
					imageUrl='/images/kuflow_mejora.png'
					linkTo='/es/solution-rpa/'
					linkText='Ver más'
				>
					<h3>KuFlow mejora</h3>
					<p>KuFlow es lo más parecido a dar superpoderes a tu equipo.</p>
					<p>
						Todos saben{' '}
						<strong>
							qué tarea tienen que hacer, se informa automáticamente
						</strong>{' '}
						a los interesados cuando hay novedades y{' '}
						<strong>los robots se encargan de lo que nadie quiere</strong>.
					</p>
				</Benefit>
				<Benefit
					posImage='R'
					imageUrl='/images/kuflow_automatiza.png'
					linkTo='/es/solution-orchestration/'
					linkText='Ver más'
				>
					<h3>KuFlow automatiza</h3>
					<p>
						Siri te cuenta chistes, Alexa te da el tiempo... ¡Pero es que{' '}
						<strong>KuFlow trabaja por ti</strong>!
					</p>
					<p>
						¿Hay que preparar informes? ¿Enviar facturas? ¿Ordenar archivos?{' '}
						<strong>Que se encarguen tus robots.</strong>
					</p>
					<p>¿Lo malo? Aún no está integrado con tu lavadora.</p>
				</Benefit>
			</section>
			<Pricing />
			<section id='faq'>
				<h2>Preguntas más frecuentes</h2>
				<FAQaccordion />
			</section>
			<LatestPostsES />
			<section id='subscription-box'>
				<h2>The KuFlow's non-automated monthly 💌</h2>
				<p>Ideas, noticias y recursos en formato newsletter para{' '}
				estar al día del mundo de la automatización.</p>
				<ModalNewsletter
					onClose={() => setShowForm(false)}
					showForm={showForm}
					lang='es'
				/>
				<a className='btn-primary' onClick={() => setShowForm(true)}>
					Suscríbete a la monthly
				</a>
				<p className='button-note'>Al suscribirte aceptas nuestra
				<a href='/legal' alt='Enlace al aviso legal'> política de privacidad</a>.</p>
			</section>
		</LayoutES>
	);
};

export default IndexPage;
